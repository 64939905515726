@use '../../../../styles/theme';

.text {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.conversation {
    display: grid;
    grid-template-columns: 1fr 0;
    gap: 4px;
    align-items: center;

    transition: all 0.15s ease-in;

    &:hover {
        padding-inline-end: 4px;
        grid-template-columns: 1fr 52px;
    }

    & > * {
        min-width: 0;
        overflow: hidden;
    }
}

.actions {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
}

.deleteAction:hover {
    color: theme.$button-primary-outlined-warning;
}
