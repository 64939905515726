@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

@value wrapper as appInvocation from "../AppResultRenderer.module.scss";

%wrapperExtend {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    width: 100%;
    gap: 10px;
}

.actions {
    @include mixins.space(5px);

    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: auto;

    & > .button {
        width: 16px;
    }
}

.action {
    @include mixins.thumb-icon(16px);
}

.revealOnHover {
    visibility: hidden;

    .appInvocation:hover & {
        visibility: visible;
    }
}

.wrapper {
    @extend %wrapperExtend;
}
