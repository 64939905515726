@use '../../../../styles/theme';

.relativeTimeHeader {
    width: 100%;
    display: flex;
    text-align: right;
    align-content: flex-end;
    flex-wrap: wrap;
    flex-direction: column;
    color: theme.$text-light-gray;

    font-size: 1.1em;
    font-weight: 700;
}

.relativeTimeHeaderInnerWrapper {
    display: flex;
    align-items: center;
    gap: 0 2px;
}
