@use 'answers-core/src/styles/theme';

.wrapper {
    height: 25px;
    min-width: 30px;
    max-width: 180px;

    .input {
        border-color: theme.$border-input-blue;
        background-color: transparent;
        color: theme.$text-mid-gray;
        border-radius: 8px;
        padding-left: 7px;
        border-width: 1px;
        height: 100%;
        cursor: pointer;
        text-overflow: ellipsis;

        &:hover {
            border-color: theme.$border-input-blue;
            background-color: white;
        }
    }
}

.icon {
    color: #8999b1;
    width: 15px;
    height: 15px;
    max-width: 15px;
    max-height: 15px;
}
