@use '../../../../styles/theme';
@use '../../../../styles/mixins';

%extendClickable {
    cursor: pointer;
    text-decoration: underline;
}

.wrapper {
    width: 100%;
    margin-inline: auto;
}

.popup {
    width: 100%;
    padding: 8px 10px;
    border: 1.5px solid theme.$uat-popup-color-main;
    background-color: theme.$uat-popup-surface;
    color: theme.$uat-popup-text-main;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05), 0px -2px 1px 0px rgba(0, 0, 0, 0.05) inset,
        0px 2px 1px 0px rgba(255, 255, 255, 0.25) inset;

    @include mixins.space-vertical(6px);

    b {
        color: theme.$uat-popup-text-heading;
    }
}

.heading {
    font-size: 1.4em;
    line-height: 1.28;
    font-weight: 600;
    color: theme.$uat-popup-text-heading;
}

.text {
    display: inline-block;
    max-width: 578px;
    font-size: 1.15em;
    line-height: 1.4;
    margin-inline: auto;
}

.fullText {
    text-align: start;
}

.clickable {
    @extend %extendClickable;
}

.showMore {
    font-weight: 600;
    @extend %extendClickable;
}
