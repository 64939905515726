@use '../../../styles/mixins';
@use '../../../styles/theme';

.wrapper {
    @include mixins.placeholder-wrapper(8px);
}

.link {
    @include mixins.link;

    color: theme.$text-blue;
    font-size: 1.6em;
}
