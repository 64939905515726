@keyframes opacityOnAndOff {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.cursor {
    animation-name: opacityOnAndOff;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.collapsedWrapper {
    min-height: 6.5em;
}

.directAnswerDescription {
    position: relative;
}

.content {
    font-style: normal;
    white-space: normal;
    line-height: 21px;
}

:global(.Typewriter) {
    display: contents;
}
