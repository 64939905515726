@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    background-color: white;
    border-radius: 5px;
    position: relative;
    border: 1px solid theme.$border-blue-gray;
}

.input {
    @include mixins.custom-scroll();
}

.button {
    transition: background-color 1s ease;
    position: absolute !important;
    bottom: calc((40px - 26px) / 2);
    right: calc((40px - 26px) / 2);
}
