@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.list {
    display: grid;
    gap: 16px;

    @include mixins.breakpoint('large') {
        grid-template-columns: repeat(3, 1fr);
    }
}

.listItem {
    display: flex;
    padding: 22px 20px;
    gap: 20px;
    color: theme.$text-mid-gray;
    background: theme.$informative-empty-state-background;
    border: 1px solid theme.$informative-empty-state-border-color;
    border-radius: 4px;

    .itemIcon {
        margin: auto 0;
    }

    @include mixins.breakpoint('large') {
        flex-direction: column;
        padding: 32px 16px 24px;
        gap: 24px;

        .itemIcon {
            margin: 0 auto;
        }

        .itemContent {
            text-align: center;
        }
    }
}

.itemIcon {
    svg {
        width: 24px;
        height: 24px;
    }

    @include mixins.breakpoint('large') {
        svg {
            width: 32px;
            height: 32px;
        }
    }
}

.itemContent {
    font-size: 1.3em;
    line-height: 1.6em;
    letter-spacing: 0.15px;
}
