@use '../../../../styles/theme';
@use '../../../../styles/mixins';

.textSection {
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: center;
    color: theme.$text-mid-gray;
    margin-bottom: 20px;
}

.title {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 500;
    color: theme.$text-positive;
}

.description {
    font-size: 16px;
    font-weight: 400;
}

.thumbnail {
    flex-shrink: 0;
    margin: 0 auto 32px;
    width: 168px;
    height: 136px;
}

.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    button {
        width: max-content;
        text-decoration: none;
    }
}

.ctaWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 8px;
    flex-direction: row;
}

.thumbIcon {
    display: inline-block;
    vertical-align: sub;
}
