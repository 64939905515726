@use 'answers-core/src/styles/mixins';
@use 'answers-core/src/styles/theme';

@value wrapper as listWrapper from '../AnswersList/AnswersList.module.scss';
@value wrapper as searchBarWrapper from 'answers-core/src/components/AskArea/AskArea.module.scss';
@value suggestionsWrapper from 'answers-core/src/components/AskArea/AskArea.module.scss';
@value suggestionsVisible from 'answers-core/src/components/AskArea/AskArea.module.scss';
@value suggestions from 'answers-core/src/components/AskArea/AskArea.module.scss';

$border-width: 4px;
$border-radius: 12px;
$mobile-padding: 16px;
$list-width: 776px;

.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;

    position: relative;
    overflow-y: hidden;
}

.header {
    padding: 0 $mobile-padding;

    @include mixins.breakpoint('large') {
        padding: 0;
        width: 100%;
        max-width: 776px;
        margin-inline: auto;
    }
}

.searchBarWrapper {
    border-width: $border-width;
    border-radius: $border-radius;

    .suggestionsVisible & {
        border-radius: $border-radius $border-radius 0 0;
        padding-bottom: $border-width;
    }
}

.suggestionsWrapper {
    border-width: $border-width;
    border-radius: 0 0 $border-radius $border-radius;
    padding: $border-radius;
}

.suggestions {
    font-size: 10px;
}

.body {
    flex: 1;
    min-height: 0;
    overflow: hidden auto;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-inline-start: $mobile-padding;
    padding-inline-end: $mobile-padding - theme.$scroll-bar-width;

    @include mixins.custom-scroll();

    @include mixins.breakpoint('large') {
        .listWrapper {
            width: 100%;
            max-width: $list-width;
            margin-inline: auto;
        }
    }
}

.hasAnswers {
    @include mixins.breakpoint('large') {
        background-color: theme.$surface-primary;
    }
}

.logo {
    display: block;
    margin: 8% auto 0;
    width: 76px;

    @include mixins.breakpoint('large') {
        width: 100px;
    }
}

.hidden {
    display: none;
}

.askAreaWrapper {
    margin: 16px 0 8px;
    @include mixins.space-vertical(8px);

    @include mixins.breakpoint('large') {
        margin-top: 24px;
    }
}

.status {
    font-size: 12px;
}

.dimmer {
    display: none;
    position: absolute;
    height: 64px;
    left: 0;
    bottom: 0;
    opacity: 0.5;
    width: calc(100% - #{theme.$scroll-bar-width});
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #d9d9d9 100%);

    @include mixins.breakpoint('large') {
        display: block;
    }
}
