@use '../../../../styles/theme';
@use '../../../../styles/mixins';

.conversations {
    height: 100%;
    padding-inline: 8px;

    overflow-y: auto;
    @include mixins.custom-scroll;
}

.item {
    min-width: 0;
    margin: 4px 0;
    padding-block: 6px;
    padding-inline: 8px;

    color: theme.$text-mid-gray;
    border-radius: 8px;
    background-color: transparent;
    transition: background-color 0.3s ease-in;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:has(.group) {
        padding-block: 2px;
        margin-bottom: -4px;
    }

    &:not(:has(.group)):hover {
        background-color: theme.$blue-gray-20;
        cursor: pointer;
    }
}

.active {
    background-color: theme.$background-light-grey;
}

.group {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.021px;
    color: theme.$text-control;
}
