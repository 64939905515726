@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

@value collapse from 'answers-core/src/components/Answers/List/More/AnswersListMore.module.scss';
@value trigger from 'answers-core/src/components/Answers/List/More/AnswersListMore.module.scss';

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    font-size: 10px;

    & .collapse {
        height: 48px;
        font-size: 12px;
        line-height: 16px;

        @include mixins.breakpoint('large') {
            height: 64px;

            font-size: 16px;
            line-height: 20px;
        }
    }

    & .trigger {
        align-items: center;

        svg {
            width: 1.2em;
            height: 1.2em;
        }
    }
}

.spacing {
    @include mixins.space-vertical(8px);
}

.list {
    @include mixins.space-vertical(10px);
}

.forceFeedback {
    z-index: map-get(theme.$zIndex, base);
    position: sticky;
    bottom: 0;
    margin-top: auto;
}
