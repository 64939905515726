@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.container {
    display: flex;
    width: 100%;
    max-width: 568px;
    margin: 0 auto;
    padding: 0 16px;

    @include mixins.breakpoint('large') {
        padding: 0;
    }

    .wrapper {
        margin-top: 10%;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    border-radius: 16px;
    border: 1px solid theme.$border-gray;
    overflow: hidden;
    background-color: theme.$surface-primary;

    @include mixins.breakpoint('large') {
        border-radius: 8px;
    }
}

.header {
    display: flex;
    flex-direction: column;
    flex: 0 0 206px;
    background: theme.$gradient-main;
}

.logo {
    display: flex;
    flex: 0 0 146px;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 12px;
}

.bevel {
    height: 2px;
    background: theme.$bevel-gradient;
}

.tagline {
    color: theme.$text-positive;
    text-align: center;
    font-size: 16px;
    font-weight: 500;

    @include mixins.breakpoint('large') {
        font-size: 22px;
    }
}

.footer {
    @include mixins.separator(15px);

    display: flex;
    justify-content: center;
    color: theme.$text-light-gray;
    margin-bottom: 24px;
    font-size: 12px;

    @include mixins.breakpoint('large') {
        font-size: 16px;
    }
}
