@use 'answers-core/src/styles/theme';

$source-gap: 1px;
$source-height: 20px;
$source-width: 20px;

.sources {
    display: flex;
    flex-direction: row-reverse;
    gap: $source-gap;
    flex-grow: 1;
}

.showAll {
    flex-wrap: wrap;
}

.info {
    background-color: theme.$border-source-collapse;
    color: theme.$text-light-gray;
    height: $source-height;
    border-radius: 4px;
    padding: 5px;
    font-size: 8px;
    font-weight: 500;
}

:export {
    sourceWidth: $source-width;
    sourceGap: $source-gap;
}
