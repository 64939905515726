@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

$footer-height: 56px;
$mobile-padding: 16px;

.wrapper {
    height: 100dvh;
    width: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.footer {
    height: $footer-height;
    display: flex;
    flex: 0 0 auto;
    padding: 0 $mobile-padding;
    justify-content: center;
    align-items: center;

    @include mixins.breakpoint('large') {
        padding: 0;
    }
}

.actions {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 0.7rem;
    align-items: center;
    height: 50%;
    font-size: 10px;
}

.icon {
    width: 20px;
    height: 20px;
}

.link {
    display: none;
    position: absolute;
    left: $mobile-padding;

    align-items: center;
    font-size: 12px;
    line-height: 24px;
    color: theme.$link-primary;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
    transition: theme.$default-transition;

    .icon {
        margin-inline-end: 8px;
    }

    &:hover {
        color: theme.$hover-blue;
    }

    @include mixins.breakpoint('medium') {
        display: inline-flex;
    }
}
