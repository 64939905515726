@use '../../../../../styles/theme';

.container {
    display: flex;
    gap: 4px;
    align-items: flex-end;
    border-radius: 4px;
    border: 1px solid transparent;

    &:focus-within {
        border-color: theme.$hover-blue;
    }
}

.input {
    height: 24px;
    font-size: 16px;
    line-height: 1;
    background-color: transparent;
    flex: 1 1 100%;
    outline: none;
}

.submitUpdate {
    color: theme.$text-light-gray;
    transition: color 0.2s ease-in;

    &:hover {
        color: theme.$text-green;
    }
}
