@use 'answers-core/src/styles/theme';

$avatar-size: 24px;

.bubble {
    position: relative;
    padding: 8px 16px;
    font-size: 1.4em;
    display: flex;
    flex-direction: column;
    border-radius: 16px;

    .content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1px;
    }

    .text {
        direction: ltr;

        p {
            margin: 0;
        }
    }

    .attributions {
        margin-top: 8px;
        position: relative;
    }

    .divider {
        color: #c9e6fc;
    }
}

.bubble[dir='rtl'] {
    border-top-right-radius: 0;
}

.bubble[dir='ltr'] {
    border-top-left-radius: 0;
}

.assistant {
    background-color: #fff;
    box-shadow: 0px 2px 6px 0px #0000001a;
    border: theme.$background-light-grey solid 1px;
    overflow-y: auto;
    max-width: 85%;
    display: flex;
}

.enhanced {
    width: 85%;
}

.user {
    background-color: theme.$message-gray;
    max-width: 75%;
}

.failed {
    background-color: theme.$red-50;
}

.avatar {
    width: $avatar-size;
    height: $avatar-size;
    font-size: 1.2em;
}

.role {
    text-transform: capitalize;
}

@keyframes opacityOnAndOff {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.cursor {
    animation-name: opacityOnAndOff;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
    margin-top: 4px;
    height: 24px;
    gap: 3px;

    & > svg {
        height: 16px;
    }
}

.actionsWrapper {
    overflow: hidden;
    visibility: hidden;
    margin-top: 8px;

    .bubble:hover & {
        visibility: visible;
    }
}

.askAiActions {
    height: 100%;
    display: flex;
    gap: 8px;

    .divider {
        margin-block: auto;
        height: 65%;
        color: theme.$blue-gray-200;
    }
}

.askAnExpertIcon {
    width: 16px;
    height: 16px;
    margin-bottom: -2px; // fixes the vertical centering
}
