@value search from '../DirectAnswer.module.scss';
@value custom from '../DirectAnswer.module.scss';

$icon-width: 24px;
$icon-height: 24px;

$border-width: 1px;
$border-margin: 2 * $border-width;
$custom-icon-image-width: 14px;
$custom-icon-image-height: 14px;

:export {
    customIconImageWidth: $custom-icon-image-width;
    customIconImageHeight: $custom-icon-image-height;
}

.directAnswersIconWrapper {
    align-items: self-start;
    flex: unset;

    width: $icon-width;
    height: $icon-height;

    .search & {
        align-items: center;
    }

    .custom & {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;

        background: linear-gradient(white, white) padding-box,
            linear-gradient(221.77deg, #ffd946 2.45%, #ed76aa 34.6%, #00b0ea 97.42%) border-box;
        border-radius: 6px;
        border: $border-width solid transparent;

        img {
            max-width: $custom-icon-image-width;
            max-height: $custom-icon-image-height;

            // for auto scale
            width: auto;
            height: auto;
        }
    }
}
