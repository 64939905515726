@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    @include mixins.answer-wrapper();
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    border: theme.$direct-answer-search-border;

    &.failed {
        background-color: theme.$red-50;
    }
}

.content {
    display: flex;
    gap: 15px;
}

.footer {
    display: flex;
    justify-content: space-between;
    padding-top: 6px;
    width: 100%;
    gap: 5px;
}

.alert {
    position: absolute;
    top: 8px;
    right: 8px;
}

.action {
    @include mixins.thumb-icon(16px);
}
