@use '../../../styles/theme';
@use '../../../styles/mixins';

@keyframes openEffect {
    40% {
        grid-template-columns: 0 auto;
    }

    100% {
        grid-template-columns: theme.$chat-history-max-width auto;
    }
}

.header {
    color: theme.$text-mid-gray;
    padding: 8px;
    font-size: 1.6em;
    letter-spacing: 0.15px;
    line-height: 24px;
}

.chat {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin: auto;
    height: 100%;
    min-height: 0;
}

.messages {
    @include mixins.custom-scroll();

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    gap: 16px;
    padding: 8px;
    overflow-y: auto;
}

.inputWrapper {
    padding: 0 8px 8px 8px;
}

.container {
    overflow: hidden; // removes leaking shadow
    height: 100%;
    min-height: 0;
    display: grid;
    grid-template-columns: 0 auto;

    & > * {
        min-width: 0;
    }
}

.historyOpen {
    animation-name: openEffect;
    animation-delay: 0.1s;
    animation-duration: 0.33s;
    animation-fill-mode: forwards;

    .history {
        opacity: 1;
    }

    .chat {
        position: relative;
        padding-inline-start: 4px; // this needs to be enough for shadow
    }
}

.history {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-height: 0;
    transition: opacity 0.5s ease-in;
    opacity: 0;

    box-shadow: 0 4px 15px 0 #0000000d, 0 1px 2px 0 #0000000d, 0 12px 20px 0 #07363d33;
}

.action {
    @include mixins.button-reset;
    padding: 4px;

    transition: color 0.3s ease-in;
    color: theme.$text-mid-gray;

    &:hover {
        transition: color 0.1s ease-in;
        color: theme.$button-primary-outlined;
    }

    &:not([disabled]):active {
        color: theme.$button-primary--hover;
    }

    &[disabled] {
        color: theme.$button-primary--disabled;
    }
}
