@use '../../../../styles/theme';
@use '../../../../styles/mixins';

@value gray from "../Answer.module.scss";
@value wrapperWebApp from "../Answer.module.scss";

.wrapper {
    @include mixins.space-vertical(12px);

    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    font-size: 1.4em;
    font-weight: 500;
    min-width: 0;

    .gray & {
        color: theme.$text-light-gray;
    }
}

h3.title {
    @include mixins.link;

    color: theme.$text-blue;
}

.collapse {
    cursor: pointer;
    font-size: 0.85em;
    font-weight: 700;
    color: theme.$text-light-gray;
    white-space: nowrap;
    margin-inline-start: 5px;
}

.wrapperWebApp {
    .collapse:hover {
        text-decoration: underline;
    }
}
