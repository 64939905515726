@use '../../../../styles/theme';
@use '../../../../styles/mixins';

@value gray from "../Answer.module.scss";

$icon-width: 22px;
$icon-height: 22px;

.wrapper {
    @include mixins.space-vertical(9px);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 0 0 $icon-width;
    gap: 5px;

    img {
        cursor: pointer;
        width: $icon-width;
        height: $icon-height;
        margin: 0;
    }
}

.confidence {
    color: theme.$text-green;
    font-weight: 700;
    font-size: 1em;
    line-height: 12px;
    white-space: nowrap;

    .gray & {
        color: theme.$text-light-gray;
    }
}
