@use '../../../../styles/theme';
@use '../../../../styles/mixins';

.collapse {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    color: theme.$text-light-gray;
    font-weight: 500;
    font-size: 1.2em;
    line-height: 15px;
    text-align: center;
}

.trigger {
    @include mixins.space(7px);
    @include mixins.link;

    display: flex;
}
