@use '../../../../styles/theme';
@use '../../../../styles/mixins';

.button {
    @include mixins.button-reset;
    padding: 6px;

    color: theme.$text-light-gray;
    transition: color 0.2s ease-in;

    &:hover {
        color: theme.$button-primary-outlined;
    }
}
