@use 'sass:math';
@use '../../../../../styles/theme';

$items-gap: 8px;
$list-padding-top: 2px;

.list {
    list-style-type: none;
    position: relative;
    box-sizing: content-box;
    min-height: 24px;
    display: flex;
    gap: $items-gap;
    overflow-y: hidden;
    overflow-x: auto;
    padding: $list-padding-top 2px 4px; // adds space to draw the shadow around items

    // hides scrollbar

    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-color: transparent transparent;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}

.dragging {
    &:active,
    &:active li {
        cursor: grab;
    }
}

.sliding {
    scroll-snap-type: x mandatory;
    overscroll-behavior-x: contain;
    scroll-padding-inline: $items-gap - 2px;

    li {
        scroll-snap-align: start;
    }
}

%dimmer {
    content: '';
    height: 100%;
    position: absolute;
    z-index: 1;
    background: linear-gradient(270deg, theme.$direct-answer-search-background 0%, rgba(227, 242, 253, 0) 100%);
}

.dimmer {
    padding-inline: math.div(theme.$answers-answer-padding-x, 2);
    margin-inline: -#{theme.$answers-answer-padding-x};
    position: relative;

    &:before {
        @extend %dimmer;
        width: theme.$answers-answer-padding-x * 1.3;
        top: 0;
        left: 0;
        transform: rotate(180deg);
    }

    &:after {
        @extend %dimmer;
        width: theme.$answers-answer-padding-x * 2;
        top: 0;
        right: 0;
    }
}

.slideButton {
    display: none;

    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    padding: 0;
    margin: 0;
    appearance: none;
    border-radius: 50%;
    border: 1px solid theme.$link-primary;
    background-color: theme.$surface-primary;
    color: theme.$link-primary;

    svg {
        margin-left: 2px;
        width: 6px;
        height: 8px;
    }
}

.displayPrevButton {
    display: flex;
    z-index: 2;
    top: calc(50% - $list-padding-top / 2);
    left: 4px;
    transform: translateY(-50%);
    position: absolute;

    svg {
        margin-left: 0;
        transform: rotate(180deg);
    }
}

.displayNextButton {
    display: flex;
    z-index: 2;
    top: calc(50% - $list-padding-top / 2);
    right: 4px;
    transform: translateY(-50%);
    position: absolute;
}
