@use 'answers-core/src/styles/theme';

$wrapper-width: 28px;

.borderWrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $wrapper-width;
    width: $wrapper-width;
}

.spin {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 5px solid transparent;
    border-top: 5px solid theme.$link-primary;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.content {
    position: relative;
    z-index: 1;
}
