@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 16px 16px 8px;
    position: relative;
    background-color: theme.$surface-primary;

    @include mixins.breakpoint('large') {
        padding: 32px 32px 16px;
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    color: theme.$text-light-gray;
    font-size: 16px;

    @include mixins.breakpoint('large') {
        font-size: 22px;
    }
}

.fields {
    @include mixins.space-vertical(18px);

    display: flex;
    flex-direction: column;
}

.buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 26px 0;
    gap: 24px;

    @include mixins.breakpoint('large') {
        gap: 40px;
    }
}

.error {
    color: theme.$text-error;
    text-align: center;
}
