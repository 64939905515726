@use '../../../../styles/theme';

.container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding-block: 8px;
    padding-inline: 8px 4px;
}

.spacer {
    flex: 1;
}

.title {
    margin: 0;
    padding: 0;
    font-size: 1.4em;
    font-weight: 500;
    letter-spacing: -0.021px;
    color: theme.$text-control;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.action {
    padding: 4px;
}
