@use 'answers-core/src/styles/theme';

.accordionHeader {
    display: flex;
    gap: 10px;
    padding: 5px;

    .title {
        color: theme.$text-mid-gray;
        font-weight: 500;
        font-size: 14px;
    }
}
