@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.renderer {
    max-width: 100%;

    :is(ul, ol, li, p, h1, h2, h3, h4, h5, h6) {
        all: revert;
    }

    ul,
    ol {
        margin-block: 0.5em;
    }

    li {
        & > ul,
        & > ol {
            margin-block: 0;
        }
    }

    ul {
        padding-inline-start: 1.8em;
    }

    ol {
        padding-inline-start: 2em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-block: 0.85em;

        & + ul,
        & + ol {
            margin-top: -0.65em;
        }
    }

    p {
        margin-block: 0.45em;
    }

    a {
        @include mixins.link;

        color: theme.$text-blue;
    }

    sup {
        a[data-footnote-ref] {
            &:before {
                content: '[';
            }

            &:after {
                content: ']';
            }

            &:before,
            &:after {
                color: theme.$text-primary;
            }
        }
    }

    pre {
        overflow: auto;
    }

    [data-footnotes] {
        // Hide the footnotes container and its children
        display: none;
    }
}
