@use '../../../../../../styles/theme';

$item-max-width: 120px;

.listItem {
    max-width: $item-max-width;
    padding: 4px 12px;
    border-radius: 100px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4px;
    user-select: none;

    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    color: theme.$text-mid-gray;

    background-color: theme.$surface-primary;

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid theme.$surface-primary;
    transition: border-color 0.3s ease-in-out;

    &:hover {
        cursor: pointer;
        border-color: theme.$link-primary;
    }
}

.refNumber {
    font-size: 8px;
    font-weight: 700;
    color: theme.$link-primary;
}

.content {
    min-width: 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    margin-inline-start: -4px;
}

.image {
    display: block;
    height: 100%;
    width: auto;
}

:export {
    itemMaxWidth: $item-max-width;
}
