@use '../../../styles/theme';

.chatHistory {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.historySearch {
    flex: 0 0 auto;
    padding: 0 8px;
}

.divider {
    margin: 8px 0;
    color: #e1e1e1;
}
