@use 'answers-core/src/styles/theme';

.container {
    width: 100%;
    height: 100%;
    min-height: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    font-size: 10px;
}

.status {
    display: flex;
    flex-direction: column;
    gap: 11px;
    background: theme.$gradient-main;
    padding: 8px 8px 10px;
}
