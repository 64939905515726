@use 'answers-core/src/styles/mixins';

.wrapper {
    @include mixins.custom-scroll();
    overflow-y: auto;
    height: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
}

.editor {
    display: flex;
    flex-flow: column nowrap;
    font-size: 1.2em;
    line-height: 21px;
    font-style: normal;
    white-space: normal;

    & > div:global(.ql-snow) {
        border: 0;
    }

    & > div:global(.ql-container) {
        font-size: 1.2em;
        line-height: 21px;
    }

    ul,
    ol {
        margin-block: 0.5em;
    }

    ul {
        padding-inline-start: 1.8em;
    }

    ol {
        padding-inline-start: 2em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-block: 0.85em;
    }

    p {
        margin-block: 0.55em;
    }
}
