@use 'answers-core/src/styles/theme';
@use 'answers-core/src/styles/mixins';

.wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    width: 100%;
    margin: auto;

    .editor {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .divider {
        color: theme.$border-blue;
        border-bottom-width: 1px;
    }

    .copy {
        color: theme.$blue-text;
        font-weight: 500;
    }
}
