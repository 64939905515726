$uib-size: 25px;
$uib-color: black;
$uib-speed: 1.3s;
$uib-dot-size: calc($uib-size * 0.24);

.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $uib-dot-size;
    width: $uib-size;

    .dot,
    &::before,
    &::after {
        content: '';
        display: block;
        height: $uib-dot-size;
        width: $uib-dot-size;
        border-radius: 50%;
        background-color: $uib-color;
        transform: scale(0);
        transition: background-color 0.3s ease;
    }

    &::before {
        animation: pulse $uib-speed ease-in-out calc($uib-speed * -0.375) infinite;
    }

    .dot {
        animation: pulse $uib-speed ease-in-out calc($uib-speed * -0.25) infinite both;
    }

    &::after {
        animation: pulse $uib-speed ease-in-out calc($uib-speed * -0.125) infinite;
    }
}

@keyframes pulse {
    0%,
    100% {
        transform: scale(0);
    }

    50% {
        transform: scale(1);
    }
}
